<template>
  <div class="column is-4" style="padding-left:28px">
    <div class="lp-column-title">고객센터 문의안내</div>
    <div style="font-size:14px;">
      <!-- 이메일 문의 -->
      <div>
        <div class="lp-cs-title">이메일 문의</div>
        <div class="lp-cs-content">
          <div style="margin-top:8px">support@launchpack.co.kr</div>
          <div>연중무휴 / 09:00~23:00</div>
        </div>
        <div style="margin-top:12px">
          <a href="mailto:support@launchpack.co.kr" @click="clickMail"><span class="lp-cs-button unselect">메일 보내기</span></a>
        </div>
      </div>

      <!-- 전화 문의 -->
      <div style="margin-top:40px">
        <div class="lp-cs-title">전화 문의</div>
        <div class="lp-cs-content">
          <div style="margin-top:8px">1544-9247</div>
          <div>09:00~18:00 / 점심 12:30~13:30</div>
          <div>주말, 공휴일 제외</div>
        </div>
        <div style="margin-top:12px" class="lp-call">
          <a href="tel:15449247"><span class="lp-cs-button unselect">전화하기</span></a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: "call-center",
    methods: {
      clickMail() {
        this.setGa('고객센터 이메일 문의', '클릭', '고객센터 이메일 문의');
      }
    }
  }
</script>

<style>
  .lp-column-title {
    font-weight: bold;
    margin-bottom: 24px;
  }

  .lp-cs-title {
    color: #a0a0a0;
    font-weight: bold;
  }

  .lp-cs-content {
    line-height: 1.8rem;
  }

  .lp-cs-button {
    cursor: pointer;
    color: #ff6600;
    padding: 8px 16px;
    font-size: 12px;
    border: 1px solid #ff6600;
    border-radius: 3px;
    width: 90px;
    text-align: center;
  }

  .lp-cs-button:hover {
    color: white;
    background-color: #ff6600;
  }

  @media (min-width:420px) {
    .lp-call {
      display: none;
    }
  }

</style>
